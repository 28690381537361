import axios from "axios";
import { API } from "./Helper";

export const axiosClient = axios.create({
  baseURL: API + "/api/admin",
});
axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

export const loginCall = (data) => {
  return axiosClient.post("/login", data);
};
export const getOrderStatuses = (callback) => {
  axiosClient
    .get("/statuses", {
      headers: {
        "content-type": "application/json",
      },
    })
    .then((response) => {
      callback(response?.data?.data);
    });
};

export const changeOrderStatus = (data, successCallback, errCallback) => {
  axiosClient
    .post("/orders/status/change", data, {
      headers: {
        "content-type": "application/json",
      },
    })
    .then((response) => {
      successCallback();
    })
    .catch((e) => {
      errCallback(e);
    });
};

export const getOrdersCall = (data) => {
  return axiosClient.post("/orders/get", data, {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const getHomeBannersCall = () => {
  return axiosClient.get("/home-banners");
};

export const deleteHomeBanner = (id) => {
  return axiosClient.delete("/home-banners/" + id);
};

export const storeHomeBannerCall = (data) => {
  return axiosClient.post("/home-banners", data, {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const getOrderDetailCall = (id) => {
  return axiosClient.get("/orders/" + id + "/get", {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const deleteSizeCall = (id) => {
  return axiosClient.delete("/sizes/" + id);
};
export const getSizesCall = (id) => {
  return axiosClient.get("/sizes");
};

export const createSizeCall = (data) => {
  return axiosClient.post("/sizes", data, {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const deleteProductCall = (id) => {
  return axiosClient.delete("/products/" + id);
};

export const storeProductCall = (data) => {
  return axiosClient.post("/products", data, {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const storeProductImagesCall = (id, data) => {
  return axiosClient.post(`/products/${id}/images`, data, {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const getProductsCall = () => {
  return axiosClient.get("/products");
};

export const getSizeDetail = (id) => {
  return axiosClient.get("/sizes/show/" + id, {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const getProductDetail = (id) => {
  return axiosClient.get("/products/show/" + id, {
    headers: {
      "content-type": "application/json",
    },
  });
};
export const deleteProductImage = (id) => {
  return axiosClient.get(`/products/${id}/images/delete`, {
    headers: {
      "content-type": "application/json",
    },
  });
};
export const updateProductCall = (id, data) => {
  return axiosClient.post("/products/update/" + id, data, {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const setSettingsCall = (data) => {
  return axiosClient.post("/settings", data, {
    headers: {
      "content-type": "application/json",
    },
  });
};
export const updateSizeCall = (id, data) => {
  return axiosClient.post("/sizes/update/" + id, data, {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const getDashboardDetailsCall = () => {
  return axiosClient.get("/get-dashboard-details", {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const getSettingsCall = () => {
  return axiosClient.get("/settings");
};
export const getCategoriesCall = () => {
  return axiosClient.get("/categories");
};

export const getUsersCall = () => {
  return axiosClient.get("/users");
};

export const deleteCategoryCall = (id) => {
  return axiosClient.delete("/categories/" + id);
};
export const storeCategoryCall = (data) => {
  return axiosClient.post("/categories", data, {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const getCategoryDetail = (id) => {
  return axiosClient.get("/categories/show/" + id, {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const updateCategoryCall = (id, data) => {
  return axiosClient.post("/categories/update/" + id, data, {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const createCouponCall = (data) => {
  return axiosClient.post("/coupons", data, {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const updateCouponCall = (id, data) => {
  return axiosClient.post("/coupons/" + id + "/update", data, {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const getCouponCall = () => {
  return axiosClient.get("/coupons", {
    headers: {
      "content-type": "application/json",
    },
  });
};
