import { useEffect, useState } from "react";
import AppLayout from "../Components/AppLayout";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import { useHistory } from "react-router-dom";
import {
  API,
  showError,
  findItem,
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  LOGO,
  HERO_BANNER,
  HERO_HEADING,
  HERO_FIRST_LINE,
  HERO_SECOND_LINE,
  ADDRESS,
  MAINTENANCE_MODE,
  DELIVERY_CHARGES,
  BANNER_COLOR,
} from "../Components/Helper";
import { getSettingsCall, setSettingsCall } from "../Components/Repository";
import Banners from "./Banners/Banners";

const Settings = (props) => {
  const [settingsData, setSettingsData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();

  const [error, setError] = useState();

  const [logo, setLogo] = useState(null);
  const [bannerLogo, setBannerLogo] = useState(null);

  const getSettings = async () => {
    try {
      let response = await getSettingsCall();
      setSettingsData(response.data.data);
    } catch (e) {
      setError(error);
    }
  };
  useEffect(() => {
    getSettings();
  }, []);

  function hexToRgba(hex) {
    if (hex) {
      const rgba = {};

      // Convert hex to RGB values
      rgba.r = parseInt(hex.slice(1, 3), 16);
      rgba.g = parseInt(hex.slice(3, 5), 16);
      rgba.b = parseInt(hex.slice(5, 7), 16);

      // Set alpha to 1 by default
      rgba.a = "1";

      return rgba;
    }
  }

  useEffect(() => {
    if (settingsData.length) {
      let color = showSetting(BANNER_COLOR);
      if (color) setColor(hexToRgba(color));
    }
  }, [settingsData]);

  const applySettingsHandler = async () => {
    try {
      let data = new FormData();
      let tmpSettingsData = [...settingsData];
      // tmpSettingsData = tmpSettingsData.filter((d) => d.input_type != "image");
      tmpSettingsData.forEach((setting) => {
        data.append(`settings[]`, JSON.stringify(setting));
      });
      if (logo) {
        data.append("logo", logo);
      }
      if (bannerLogo) {
        data.append("hero_banner_img", bannerLogo);
      }
      let response = await setSettingsCall(data);

      console.log("aadada");
      setIsSubmitted(true);
      window.scrollTo({ top: 0, behavior: "smooth" });

      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (e) {}
  };

  const showSetting = (sKey) => {
    let item = findItem(settingsData, "key", sKey);
    return item?.value;
  };
  const newSetting = (event, key, isFile = false) => {
    console.log("event", event);
    let i = settingsData?.findIndex((setting) => setting?.key === key);
    let tmpSettingData = [...settingsData];

    if (isFile) {
      if (key == LOGO) {
        setLogo(event.target.files[0]);
      }
      if (key == "hero_banner_img") {
        setBannerLogo(event.target.files[0]);
      }
    } else {
      if (key == MAINTENANCE_MODE) {
        tmpSettingData[i].value = event;
      } else if (key == BANNER_COLOR) {
        tmpSettingData[i].value = event.hex;
      } else {
        tmpSettingData[i].value = event?.target?.value;
      }
    }
    setSettingsData(tmpSettingData);
  };

  //colorwork here121
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "1",
  });

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor) => {
    setColor(newColor.rgb);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <>
      <AppLayout active={props.active}>
        <div className="main-content">
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  {isSubmitted ? (
                    <div class="alert alert-success" role="alert">
                      Changes Applied Successfully.
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="overview-wrap">
                    <h2 className="title-1 mx-3">Change Settings</h2>
                  </div>
                  <div className="col-lg-11 mt-3">
                    <div className="card">
                      <div className="card-header">
                        <strong>Settings</strong>
                      </div>
                      <div className="card-body card-block">
                        <form
                          method="post"
                          encType="multipart/form-data"
                          className="form-horizontal"
                        >
                          {/* SUPPORT EMAIL  */}
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="text-input"
                                className=" form-control-label"
                              >
                                Support Email
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                onChange={(e) => {
                                  newSetting(e, SUPPORT_EMAIL);
                                }}
                                value={showSetting(SUPPORT_EMAIL)}
                                type="text"
                                id="text-input"
                                name="text-input"
                                className="form-control"
                              />

                              <div className="text-danger">
                                {showError("size", error)}
                              </div>
                            </div>
                          </div>
                          {/* SUPPORT PHONE NUMBER  */}
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="text-input"
                                className=" form-control-label"
                              >
                                Support Phone Number
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                onChange={(e) => {
                                  newSetting(e, SUPPORT_PHONE);
                                }}
                                value={showSetting(SUPPORT_PHONE)}
                                type="tel"
                                id="text-input"
                                name="text-input"
                                className="form-control"
                              />

                              <div className="text-danger">
                                {showError("size", error)}
                              </div>
                            </div>
                          </div>
                          {/* LOGO  */}
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="text-input"
                                className=" form-control-label"
                              >
                                Logo
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                type={"file"}
                                onChange={(e) => {
                                  newSetting(e, LOGO, true);
                                }}
                              />
                              <img
                                className="w-25"
                                alt="Logo"
                                src={showSetting(LOGO)}
                              />
                              <div className="text-danger">
                                {showError("size", error)}
                              </div>
                            </div>
                          </div>
                          {/* HERO BANNER IMAGE  */}
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="text-input"
                                className=" form-control-label"
                              >
                                Hero Banner Image
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                type={"file"}
                                onChange={(e) => {
                                  newSetting(e, "hero_banner_img", true);
                                }}
                              />
                              <img
                                className="w-25"
                                alt="Hero Banner"
                                src={showSetting(HERO_BANNER)}
                              />
                              <div className="text-danger">
                                {showError("size", error)}
                              </div>
                            </div>
                          </div>
                          {/* HERO HEADING  */}
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="text-input"
                                className=" form-control-label"
                              >
                                Hero Heading
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                onChange={(e) => {
                                  newSetting(e, HERO_HEADING);
                                }}
                                value={showSetting(HERO_HEADING)}
                                type="text"
                                id="text-input"
                                name="text-input"
                                className="form-control"
                              />

                              <div className="text-danger">
                                {showError("size", error)}
                              </div>
                            </div>
                          </div>
                          {/* HERO FIRST LINE  */}
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="text-input"
                                className=" form-control-label"
                              >
                                Hero First Line
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                onChange={(e) => {
                                  newSetting(e, HERO_FIRST_LINE);
                                }}
                                value={showSetting(HERO_FIRST_LINE)}
                                type="text"
                                id="text-input"
                                name="text-input"
                                className="form-control"
                              />

                              <div className="text-danger">
                                {showError("size", error)}
                              </div>
                            </div>
                          </div>
                          {/* HERO SECOND LINE  */}
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="text-input"
                                className=" form-control-label"
                              >
                                Hero Second Line
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                onChange={(e) => {
                                  newSetting(e, HERO_SECOND_LINE);
                                }}
                                value={showSetting(HERO_SECOND_LINE)}
                                type="text"
                                id="text-input"
                                name="text-input"
                                className="form-control"
                              />

                              <div className="text-danger">
                                {showError("size", error)}
                              </div>
                            </div>
                          </div>
                          {/* Delivery Charges  */}

                          {/* Color Pallete  here121*/}

                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="text-input"
                                className=" form-control-label"
                              >
                                Hero Text Color:
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <div style={styles.swatch} onClick={handleClick}>
                                <div style={styles.color} />
                              </div>
                              {displayColorPicker ? (
                                <div style={styles.popover}>
                                  <div
                                    style={styles.cover}
                                    onClick={handleClose}
                                  />
                                  <SketchPicker
                                    color={color}
                                    onChange={(e) => {
                                      newSetting(e, BANNER_COLOR);
                                    }}
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>

                          {/* Color Pallete  */}

                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="text-input"
                                className=" form-control-label"
                              >
                                Delivery Charges
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                onChange={(e) => {
                                  newSetting(e, DELIVERY_CHARGES);
                                }}
                                value={showSetting(DELIVERY_CHARGES)}
                                type="text"
                                id="text-input"
                                name="text-input"
                                className="form-control"
                              />

                              <div className="text-danger">
                                {showError("size", error)}
                              </div>
                            </div>
                          </div>
                          {/* ADDRESS  */}
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="text-input"
                                className=" form-control-label"
                              >
                                Address
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                onChange={(e) => {
                                  newSetting(e, ADDRESS);
                                }}
                                value={showSetting(ADDRESS)}
                                type="text"
                                id="text-input"
                                name="text-input"
                                className="form-control"
                              />

                              <div className="text-danger">
                                {showError("size", error)}
                              </div>
                            </div>
                          </div>
                          {/* MODE  */}
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="text-input"
                                className=" form-control-label"
                              >
                                Maintenance mode
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                checked={
                                  showSetting(MAINTENANCE_MODE) == 0
                                    ? false
                                    : true
                                }
                                onChange={(e) => {
                                  newSetting(
                                    showSetting(MAINTENANCE_MODE) == 0
                                      ? "1"
                                      : "0",
                                    MAINTENANCE_MODE
                                  );
                                }}
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                              />
                              <div className="text-danger">
                                {showError("maintenance_mode", error)}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="card-footer d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm"
                          onClick={applySettingsHandler}
                        >
                          <i className="fa fa-dot-circle-o" /> Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default Settings;
