import React, { useState } from "react";
import { API, showError } from "../../Components/Helper";
import { useHistory } from "react-router-dom";
import AppLayout from "../../Components/AppLayout";
import { createSizeCall } from "../../Components/Repository";

const CreateSizes = (props) => {
  const history = useHistory();

  const [error, setError] = useState();

  const [productSize, setProductSize] = useState("");

  const onSubmitNewSize = async () => {
    setError(null);

    const h = {};
    let data = new FormData();

    data.append("name", productSize);

    h.Accept = "application/json";
    try {
      let response = await createSizeCall(data);
      history.push("/sizes");
    } catch (e) {
      setError(e);
    }
  };

  const ProductSizeHandler = (event) => {
    setProductSize(event.target.value);
  };

  const resetFormHandler = () => {
    setProductSize("");
    setError(null);
  };

  return (
    <>
      <AppLayout active={props.active}>
        <div className="main-content">
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="overview-wrap">
                    <h2 className="title-1 mx-3">Add a New Size</h2>
                  </div>
                  <div className="col-lg-11 mt-3">
                    <div className="card">
                      <div className="card-header">
                        <strong>Product Size</strong>
                      </div>
                      <div className="card-body card-block">
                        <form
                          method="post"
                          encType="multipart/form-data"
                          className="form-horizontal"
                        >
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="text-input"
                                className=" form-control-label"
                              >
                                Size
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                value={productSize}
                                onChange={ProductSizeHandler}
                                type="text"
                                id="text-input"
                                name="text-input"
                                placeholder="Size in ml"
                                className="form-control"
                              />
                              <small className="text-muted">e.g 'xx ml'</small>
                              <div className="text-danger">
                                {showError("size", error)}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="card-footer d-flex justify-content-end">
                        <button
                          type="reset"
                          onClick={resetFormHandler}
                          className="btn btn-danger btn-sm mx-3"
                        >
                          <i className="fa fa-ban" /> Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm"
                          onClick={onSubmitNewSize}
                        >
                          <i className="fa fa-dot-circle-o" /> Add Size
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default CreateSizes;
