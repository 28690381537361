import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export const showError = (key, error) => {
  if (error?.response?.data && error?.response?.data[key]) {
    return error.response.data[key];
  }
  if (error?.response?.data?.errors) {
    return error?.response?.data?.errors[key]?.length
      ? error?.response?.data?.errors[key][0]
      : null;
  } else return "";
};
export const API = "https://api.organical.pk";
// export const API = "http://127.0.0.1:8000";

export const PENDING = "Pending";
export const READY_TO_SHIP = "Ready To Ship";
export const SHIPPED = "Shipped";
export const DELEVIRED = "Delivered";
export const CANCELLED = "Cancelled";
export const RETURNED = "Returned";
export const FAILED_DELIVERY = "Failed Delivery";

export const findItem = (arr, key, value) => {
  return arr.find((item) => item[key] === value);
};

export const SUPPORT_EMAIL = "support_email";
export const SUPPORT_PHONE = "support_phone_number";
export const LOGO = "logo";
export const HERO_BANNER = "hero_banner_img";
export const HERO_HEADING = "hero_heading";
export const HERO_FIRST_LINE = "hero_first_line";
export const HERO_SECOND_LINE = "hero_second_line";
export const MAINTENANCE_MODE = "maintenance_mode";
export const DELIVERY_CHARGES = "delivery_charges";
export const BANNER_COLOR = "banner_color";

export const ADDRESS = "address";

export const dateFunc = (date) => {
  const formattedDate = new Date(date).toLocaleDateString(
    {},
    { timeZone: "UTC", month: "long", day: "2-digit", year: "numeric" }
  );
  const formattedTime = new Date(date).toLocaleTimeString("en-US");
  let Date_time = {};
  Date_time.date = formattedDate;
  Date_time.time = formattedTime;

  return Date_time;
};

export const confirmation = (success) => {
  confirmAlert({
    title: "Warning",
    message: "Are you sure to do this?",
    buttons: [
      {
        label: "Yes",
        onClick: () => success(),
      },
      {
        label: "No",
        onClick: () => {},
      },
    ],
  });
};
