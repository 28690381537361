import React, { useEffect, useState } from "react";
import AppLayout from "../../Components/AppLayout";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import {
  deleteHomeBanner,
  getHomeBannersCall,
} from "../../Components/Repository";
import { confirmation } from "../../Components/Helper";

const Banners = (props) => {
  const [message, setMessage] = useState("");

  const columns = [
    {
      name: "Sr No.",
      selector: (row, index) => index + 1,
    },

    {
      name: "Image",
      selector: (row) => row.img,
      format: (row) => {
        return (
          <>
            <img className="w-75 h-50" src={row.img} alt="" />
          </>
        );
      },
    },

    {
      cell: (row) => (
        <div className="d-flex table-data-feature">
          <button
            className="item"
            onClick={() => confirmation(()=>{deleteCategoryHandler(row.id)})}
          >
            <i className="zmdi zmdi-delete text-danger" />
          </button>
        </div>
      ),
    },
  ];
  const history = useHistory();

  const [BannerData, setBannerData] = useState();

  const getDataCall = async () => {
    const response = await getHomeBannersCall();
    setBannerData(response.data.data);
  };
  const createBannerHandler = () => {
    history.push("/createbanner");
  };

  useEffect(() => {
    getDataCall();
  }, []);

  const deleteCategoryHandler = async (id) => {
    let response = await deleteHomeBanner(id);
    getDataCall();
    setMessage(response.data.message);
  };

  const deleteNotification = message ? (
    <div class="alert alert-success" role="alert">
      {message}
    </div>
  ) : null;

  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);

  return (
    <>
      <AppLayout active={props.active}>
        <div className="main-content">
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="overview-wrap">{deleteNotification}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid px-5">
            <div className=" d-flex justify-content-between">
              <h2 className="title-1 my-3">Banners</h2>
              <button
                onClick={createBannerHandler}
                className="mx-5 text-success"
              >
                <i className="zmdi zmdi-plus-circle mx-2" />
                Add A New Banner
              </button>
            </div>
            <DataTable columns={columns} data={BannerData} pagination />
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default Banners;
