import React, { useEffect, useState } from "react";
import AppLayout from "../../Components/AppLayout";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { API, confirmation } from "../../Components/Helper";
import {
  deleteCategoryCall,
  getCategoriesCall,
  getUsersCall,
} from "../../Components/Repository";

const Users = (props) => {
  const [message, setMessage] = useState("");

  const columns = [
    {
      name: "Sr No.",
      selector: (row, index) => index + 1,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
  ];
  const history = useHistory();

  const [CategoryData, setCategoryData] = useState();

  const getDataCall = async () => {
    let response = await getUsersCall();
    setCategoryData(response.data.users);
  };
  const createCategoryHandler = () => {
    history.push("/createcategory");
  };

  useEffect(() => {
    getDataCall();
  }, []);

  const deleteCategoryHandler = async (id) => {
    let response = await deleteCategoryCall(id);
    getDataCall();
    setMessage(response.data.message);
  };

  const deleteNotification = message ? (
    <div class="alert alert-success" role="alert">
      {message}
    </div>
  ) : null;

  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);

  const editCategoryHandler = (id) => {
    history.push("/categories/" + id + "/edit");
  };

  return (
    <>
      <AppLayout active={props.active}>
        <div className="main-content">
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="overview-wrap">{deleteNotification}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid px-5">
            <div className=" d-flex justify-content-between">
              <h2 className="title-1 my-3">Users</h2>
            </div>
            <DataTable columns={columns} data={CategoryData} pagination />
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default Users;
