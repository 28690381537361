/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AppLayout from "../../Components/AppLayout";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { API, confirmation } from "../../Components/Helper";
import { deleteSizeCall, getSizesCall } from "../../Components/Repository";
// import ReactHtmlParser from "react-html-parser";
const Sizes = (props) => {
  const [message, setMessage] = useState("");

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
    },
    {
      name: "Size",
      selector: (row) => row.name,
    },
    {
      cell: (row) => (
        <div className="d-flex table-data-feature ">
          <button className="item " 
       
          
          onClick={() => confirmation(()=>{deleteSizeHandler(row.id)})}
          >
            <i className="zmdi zmdi-delete text-danger" />
          </button>
          <button onClick={() => editSizeHandler(row.id)} className="mx-3 item">
            <i className="zmdi zmdi-edit text-primary " />
          </button>
        </div>
      ),
    },
  ];
  const history = useHistory();

  const [sizeData, setSizeData] = useState([]);

  const getDataCall = async () => {
    if (!sizeData.length) {
      let response = await getSizesCall();
      setSizeData(response.data.sizes);
    }
  };
  const createSizeHandler = () => {
    history.push("/createsizes");
  };

  useEffect(() => {
    getDataCall();
  }, []);

  const deleteSizeHandler = async (id) => {
    let response = await deleteSizeCall(id);
    setSizeData(sizeData.filter((obj) => obj.id !== id));
    setMessage(response.data.message);
  };

  const deleteNotification = message ? (
    <div class="alert alert-success" role="alert">
      {message}
    </div>
  ) : null;

  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);

  const editSizeHandler = (id) => {
    history.push("/sizes/" + id + "/edit");
  };

  return (
    <>
      <AppLayout active={props.active}>
        <div className="main-content">
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                <div className="overview-wrap">{deleteNotification}</div>
              </div>
            </div>
          </div>
          <div className="container-fluid px-5">
            <div className=" d-flex justify-content-between">
              <h2 className="title-1 my-3">Sizes</h2>
              <button onClick={createSizeHandler} className="mx-5 text-success">
                <i className="zmdi zmdi-plus-circle mx-2" />
                Add A New Size
              </button>
            </div>
            <DataTable columns={columns} data={sizeData} pagination />
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default Sizes;
