/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { API, showError } from "../../Components/Helper";
import AppLayout from "../../Components/AppLayout";
import { getSizeDetail, updateSizeCall } from "../../Components/Repository";

const EditSizes = (props) => {
  const history = useHistory();

  const [error, setError] = useState();

  const [productSize, setProductSize] = useState("");

  const { id } = useParams();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    try {
      let response = await getSizeDetail(id);
      setProductSize(response.data.data.name);
    } catch (e) {
      setError(e);
    }
  };

  const onSubmitNewSize = async () => {
    setError(null);

    let data = new FormData();

    data.append("name", productSize);

    try {
      let response = await updateSizeCall(id, data);

      history.push("/sizes");
    } catch (error) {
      setError(error);
    }
  };

  const ProductSizeHandler = (event) => {
    setProductSize(event.target.value);
  };

  const resetFormHandler = () => {
    setProductSize("");
    setError(null);
  };

  return (
    <>
      <AppLayout active={props.active}>
        <div className="main-content">
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="overview-wrap">
                    <h2 className="title-1 mx-3">Update Size</h2>
                  </div>
                  <div className="col-lg-11 mt-3">
                    <div className="card">
                      <div className="card-header">
                        <strong>Update Size</strong>
                      </div>
                      <div className="card-body card-block">
                        <form
                          action
                          method="post"
                          encType="multipart/form-data"
                          className="form-horizontal"
                        >
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="text-input"
                                className=" form-control-label"
                              >
                                Size
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                value={productSize}
                                onChange={ProductSizeHandler}
                                type="text"
                                id="text-input"
                                name="text-input"
                                placeholder="Size in ml"
                                className="form-control"
                              />
                              <small className="text-muted">e.g 'xx ml'</small>

                              <div className="text-danger">
                                {showError("size", error)}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="card-footer d-flex justify-content-end">
                        <button
                          type="reset"
                          onClick={resetFormHandler}
                          className="btn btn-danger btn-sm mx-3"
                        >
                          <i className="fa fa-ban" /> Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm"
                          onClick={onSubmitNewSize}
                        >
                          <i className="fa fa-dot-circle-o" /> Update Size
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default EditSizes;
