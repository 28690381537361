import React, { useState } from "react";
import { API, showError } from "../../Components/Helper";
import { useHistory } from "react-router-dom";
import AppLayout from "../../Components/AppLayout";
import { storeCategoryCall } from "../../Components/Repository";

const CreateCategory = (props) => {
  const history = useHistory();

  const [error, setError] = useState();

  const [featured, setFeatured] = useState("yes");

  const [productName, setProductName] = useState("");

  const [productImage, setProductImage] = useState([]);

  const onSubmitNewCategory = async () => {
    setError(null);

    const h = {};
    let data = new FormData();

    productImage.forEach((img) => {
      data.append(`image`, img);
    });
    data.append("name", productName);
    data.append("featured", featured === "yes" ? true : false);

    h.Accept = "application/json";

    try {
      let response = await storeCategoryCall(data);
      setProductImage(null);
      history.push("/categories");
    } catch (error) {
      setError(error);
    }
  };

  const ProductNameHandler = (event) => {
    setProductName(event.target.value);
  };

  const radioChangeHandler = (event) => {
    setFeatured(event.target.value);
  };

  const imageChangeHandler = (event) => {
    setProductImage([...productImage, ...event.target.files]);
  };

  const resetFormHandler = () => {
    setProductName("");
    document.getElementById("file-multiple-input").value = "";
    document.getElementById("image_show").innerHTML = "";
    setProductImage(null);
    setFeatured("yes");
    setError(null);
  };

  return (
    <>
      <AppLayout active={props.active}>
        <div className="main-content">
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="overview-wrap">
                    <h2 className="title-1 mx-3">Add a New Category</h2>
                  </div>
                  <div className="col-lg-11 mt-3">
                    <div className="card">
                      <div className="card-header">
                        <strong>Product Category</strong>
                      </div>
                      <div className="card-body card-block">
                        <form
                          action
                          method="post"
                          encType="multipart/form-data"
                          className="form-horizontal"
                        >
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="text-input"
                                className=" form-control-label"
                              >
                                Name
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                value={productName}
                                onChange={ProductNameHandler}
                                type="text"
                                id="text-input"
                                name="text-input"
                                placeholder="Enter Category Name"
                                className="form-control"
                              />
                              <div className="text-danger">
                                {showError("name", error)}
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label className=" form-control-label">
                                Featured
                              </label>
                            </div>
                            <div className="col col-md-9">
                              <div className="form-check-inline form-check">
                                <label
                                  htmlFor="inline-radio1"
                                  className="form-check-label "
                                >
                                  <input
                                    value="yes"
                                    checked={featured === "yes" ? true : false}
                                    onChange={radioChangeHandler}
                                    type="radio"
                                    id="inline-radio1"
                                    name="inline-radios"
                                    className="form-check-input"
                                  />
                                  Yes
                                </label>
                                <label
                                  htmlFor="inline-radio2"
                                  className="form-check-label mx-3"
                                >
                                  <input
                                    value="no"
                                    checked={featured === "no" ? true : false}
                                    onChange={radioChangeHandler}
                                    type="radio"
                                    id="inline-radio2"
                                    name="inline-radios"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                              <div className="text-danger">
                                {showError("featured", error)}
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="file-multiple-input"
                                className=" form-control-label"
                              >
                                Upload Images
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                type="file"
                                id="file-multiple-input"
                                name="file-multiple-input"
                                multiple
                                className="form-control-file"
                                onChange={(e) => imageChangeHandler(e)}
                              />
                              <small className="text-muted">
                                **image size should be 270x270 pixels
                              </small>
                              <div className="text-danger">
                                {showError("image", error)}
                              </div>
                              <div className="d-flex justify-content-evenly">
                                {productImage.map((image) => {
                                  // console.log("sadsda", productImage);
                                  return (
                                    <img
                                      className="mx-2 w-25 h-25"
                                      id="image_show"
                                      multiple
                                      alt="preview"
                                      src={
                                        image ? URL.createObjectURL(image) : ""
                                      }
                                    />
                                  );
                                })}
                                {/* <img
                                  className="mx-2"
                                  id="image_show"
                                  multiple
                                  src={
                                    productImage
                                      ? URL.createObjectURL(productImage.img2)
                                      : ""
                                  }
                                /> */}
                              </div>
                            </div>
                          </div>
                          {/* <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="file-multiple-input"
                                className=" form-control-label"
                              >
                                Upload Images
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                type="file"
                                id="file-multiple-input"
                                name="file-multiple-input"
                                multiple
                                className="form-control-file"
                                onChange={(e) => imageChangeHandler(e)}
                              />
                              <small className="text-muted">
                                **image size should be 270x270 pixels
                              </small>
                              <div className="text-danger">
                                {showError("image", error)}
                              </div>
                              <img
                                id="image_show"
                                src={
                                  productImage
                                    ? URL.createObjectURL(productImage)
                                    : ""
                                }
                              />
                            </div>
                          </div> */}
                        </form>
                      </div>
                      <div className="card-footer d-flex justify-content-end">
                        <button
                          type="reset"
                          onClick={resetFormHandler}
                          className="btn btn-danger btn-sm mx-3"
                        >
                          <i className="fa fa-ban" /> Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm"
                          onClick={onSubmitNewCategory}
                        >
                          <i className="fa fa-dot-circle-o" /> Add New Category
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default CreateCategory;
