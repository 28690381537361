import React from "react";
import { API, showError } from "../Components/Helper";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { loginCall } from "../Components/Repository";

const Login = () => {
  const [adminEmail, setAdminEmail] = useState();
  const [adminPassword, setAdminPassword] = useState();
  const [error, setError] = useState(null);

  const history = useHistory();

  const emailHandler = (event) => {
    setAdminEmail(event.target.value);
  };

  const passwordHandler = (event) => {
    setAdminPassword(event.target.value);
  };

  const LoginHandler = async () => {
    setError(null);
    const data = { email: adminEmail, password: adminPassword };
    try {
      let response = await loginCall(data);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("token", response.data.token);
      history.push("/dashboard");
    } catch (e) {
      setError(e);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content--bge5">
          <div className="container">
            <div className="login-wrap">
              <div className="login-content">
                <div className="login-logo">
                  <a href="#/">
                    <img src="/images/icon/logo.png" className="w-50" alt="CoolAdmin" />
                  </a>
                </div>
                <div className="login-form">
                  <form method="post">
                    <div className="form-group">
                      <label>Email Address</label>
                      <input
                        className="au-input au-input--full"
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={emailHandler}
                      />
                    </div>
                    <div className="text-danger">
                      {showError("email", error)}
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        className="au-input au-input--full"
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={passwordHandler}
                      />
                    </div>
                    <div className="text-danger">
                      {showError("password", error)}
                    </div>

                    <div className="text-danger">
                      {showError("error", error)}
                    </div>
                    {/* <div className="login-checkbox"> */}
                      {/* <label>
                        <input type="checkbox" name="remember" />
                        Remember Me
                      </label>
                      <label>
                        <Link to="/forgotpassword">Forgotten Password?</Link>
                      </label>
                    </div> */}
                    {/* <button
                      className="au-btn au-btn--block au-btn--green m-b-20"
                      type="submit"
                    >
                      Log in
                    </button> */}
                    <button
                      onClick={LoginHandler}
                      type="button"
                      className="au-btn au-btn--block au-btn--green m-b-20 justify-content-center d-flex"
                    >
                      LOG IN
                    </button>

                    {/* <div className="social-login-content">
                      <div className="social-button">
                        <button className="au-btn au-btn--block au-btn--blue m-b-20">
                          sign in with facebook
                        </button>
                        <button className="au-btn au-btn--block au-btn--blue2">
                          sign in with twitter
                        </button>
                      </div>
                    </div> */}
                  </form>
                  {/* <div className="register-link">
                    <p>
                      Don't have an account?
                      <a href="#/">Sign Up Here</a>
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
