import React from "react";
import { useHistory } from "react-router-dom";

const Sidebar = (props) => {
  const history = useHistory();

  const categoriesLinkHandler = () => {
    history.push("/categories");
  };
  const dashboardLinkHandler = () => {
    history.push("/dashboard");
  };
  const LogoLinkHandler = () => {
    history.push("/dashboard");
  };
  const productsLinkHandler = () => {
    history.push("/products");
  };
  const sizesLinkHandler = () => {
    history.push("/sizes");
  };
  const bannersLinkHandler = () => {
    history.push("/banners");
  };
  const ordersLinkHandler = () => {
    history.push("/orders");
  };

  return (
    <>
      <aside className="menu-sidebar d-none d-lg-block">
        <div className="logo">
          <a onClick={LogoLinkHandler} href="#/">
            <img
              className="rounded-circle p-5  w-80"
              src="images/icon/logo.png"
              alt="Cool Admin"
            />
          </a>
        </div>
        <div className="menu-sidebar__content js-scrollbar1">
          <nav className="navbar-sidebar">
            <ul className="list-unstyled navbar__list">
              <li className={props.active === "dashboard" ? "active" : ""}>
                <a onClick={dashboardLinkHandler} href="#/">
                  <i className="zmdi zmdi-view-dashboard" />
                  Dashboard
                </a>
              </li>
              <li className={props.active === "categories" ? "active" : ""}>
                <a onClick={categoriesLinkHandler} href="#/">
                  <i className="fas fa-list" />
                  Categories
                </a>
              </li>
              <li className={props.active === "products" ? "active" : ""}>
                <a onClick={productsLinkHandler} href="#/">
                  <i className="fa fa-th-large" />
                  Products
                </a>
              </li>
              <li className={props.active === "sizes" ? "active" : ""}>
                <a onClick={sizesLinkHandler} href="#/">
                  <i className="fa fa-flask" />
                  Sizes
                </a>
              </li>
              <li className={props.active === "banners" ? "active" : ""}>
                <a onClick={bannersLinkHandler} href="#/">
                  <i className="zmdi zmdi-vignette" />
                  Banners
                </a>
              </li>
              {/* history.push("/orders"); */}
              <li className={props.active === "orders" ? "active" : ""}>
                <a onClick={ordersLinkHandler} href="#/">
                  <i className="zmdi zmdi-shopping-basket" />
                  Orders
                </a>
              </li>
              <li className={props.active === "users" ? "active" : ""}>
                <a
                  onClick={() => {
                    history.push("/users");
                  }}
                  href="#/"
                >
                  <i class="fa fa-users" aria-hidden="true"></i>
                  Users
                </a>
              </li>

              <li className={props.active === "coupons" ? "active" : ""}>
                <a
                  onClick={() => {
                    history.push("/coupons");
                  }}
                  href="#/"
                >
                  <i class="fa fa-users" aria-hidden="true"></i>
                  Coupons
                </a>
              </li>
              <li className={props.active === "settings" ? "active" : ""}>
                <a
                  onClick={() => {
                    history.push("/settings");
                  }}
                  href="#/"
                >
                  <i className="zmdi zmdi-shopping-basket" />
                  Settings
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
