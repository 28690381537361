import React from "react";

const Footer = () => {
  return (
    <>
      <div className="col-md-12">
        <div className="copyright">
          <p>
            Copyright © 2020 Codart Technologies. All rights reserved.
            <br></br>
            <a href="https://Codart-Technologies.com"> Codart-Technologies </a>.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
