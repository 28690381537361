/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { API, showError } from "../../Components/Helper";
import { useHistory, useParams } from "react-router-dom";
import AppLayout from "../../Components/AppLayout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  deleteProductImage,
  getCategoriesCall,
  getProductDetail,
  getSizesCall,
  storeProductImagesCall,
  updateProductCall,
} from "../../Components/Repository";

const EditProduct = (props) => {
  const history = useHistory();

  const [error, setError] = useState();

  const [featured, setFeatured] = useState("yes");

  const [available, setAvailable] = useState("yes");

  const [sizes, setSizes] = useState([]);

  const [categories, setCategories] = useState([]);

  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  const [variants, setVariants] = useState([]);

  const [productName, setProductName] = useState("");

  const [productDescription, setProductDescription] = useState("");

  const [productDetails, setProductDetails] = useState("");

  const [productImage, setProductImage] = useState([]);

  const [alreadyStoredProductImage, setAlreadyStoredProductImage] = useState(
    []
  );
  const { id } = useParams();

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    console.log("productImage", productImage);
  }, [productImage]);
  const getDetails = async () => {
    try {
      let response = await getProductDetail(id);
      setProductName(response.data.data.name);
      setProductDescription(response.data.data.description);
      setProductDetails(response.data.data.product_detail);
      setSelectedCategoryId(response.data.data.category_id);
      setFeatured(response.data.data.featured ? "yes" : "no");
      setAvailable(response.data.data.available ? "yes" : "no");
      let imgs = response.data.data.images;
      setAlreadyStoredProductImage(imgs);
      setVariants(
        response.data.data.variants.map((v) => {
          let tmp = { ...v };
          tmp.featured = tmp.featured === 1 ? "yes" : "no";
          tmp.discount = tmp.discount === 1 ? "yes" : "no";
          tmp.available = tmp.available === 1 ? "yes" : "no";
          return tmp;
        })
      );
    } catch (error) {}
  };

  const onSubmitNewProduct = async () => {
    // console.log('asdsadsa',available)
    // console.log('feafa',featured)

    setError(null);
    const h = {};
    // let data = new FormData();

    // data.append("name", productName);
    // data.append("featured", featured === "yes" ? true : false);
    // data.append("available", available === "yes" ? true : false);
    // data.append("description", productDescription);
    // data.append("product_detail", productDetails);
    // data.append("category_id", selectedCategoryId);

    let data = {
      name: productName,
      featured: featured === "yes" ? "true" : "false",
      available: available === "yes" ? "true" : "false",
      description: productDescription,
      product_detail: productDetails,
      category_id: selectedCategoryId,
      variants: [],
    };
    variants.forEach((item) => {
      let variant = { ...item };
      variant.featured = item.featured === "yes" ? true : false;
      variant.available = item.available === "yes" ? true : false;
      variant.discount = item.discount === "yes" ? true : false;
      data.variants.push(variant);
    });
    try {
      let repsonse = await updateProductCall(id, data);
      if (productImage.length) {
        let data = new FormData();
        productImage.forEach((img) => {
          data.append(`images[]`, img);
        });
        let repsonse1 = await storeProductImagesCall(
          repsonse?.data?.data.id,
          data
        );
      }

      history.push("/products");
    } catch (error) {
      setError(error);
    }
  };

  const deleteNewlyStoredImage = async (index) => {
    let tmp = [...productImage];
    setProductImage(tmp.filter((t, i) => i != index));
  };

  const deleteAlreadyStoredImage = async (id) => {
    try {
      let response = await deleteProductImage(id);
      let tmp = [...alreadyStoredProductImage];
      setAlreadyStoredProductImage(tmp.filter((t) => t.id != id));
    } catch (e) {}
  };

  const imageChangeHandler = (event) => {
    setProductImage([...productImage, ...event.target.files]);
  };
  const variantHandler = () => {
    setVariants([
      ...variants,
      {
        size_id: "",
        featured: "yes",
        available: "yes",
        discount: "no",
        price: "0",
        discounted_price: "0",
      },
    ]);
  };

  const ProductNameHandler = (event) => {
    setProductName(event.target.value);
  };

  const ProductDescriptionHandler = (event) => {
    setProductDescription(event.target.value);
  };

  const featuredRadioChangeHandler = (event) => {
    setFeatured(event.target.value);
  };

  const availableRadioChangeHandler = (event) => {
    setAvailable(event.target.value);
  };

  // function availableRadioChangeHandler(event) {
  //   console.log('asdsadas inradio')
  //   setAvailable(event.target.value);
  // }

  const variantInputsChangeHandler = (value, index, key) => {
    let tmpVariants = [...variants];
    tmpVariants[index][key] = value;
    setVariants(tmpVariants);
  };
  const getSizes = async () => {
    let response = await getSizesCall();
    setSizes(response.data.sizes);
  };
  useEffect(() => {
    getSizes();
  }, []);

  const getCategories = async () => {
    let response = await getCategoriesCall();
    setCategories(response.data.categories);
  };
  useEffect(() => {
    getCategories();
  }, []);

  const resetFormHandler = () => {
    setVariants([]);
    setProductName("");
    setProductDescription("");
    setProductDetails("");
    setFeatured("yes");
    setAvailable("yes");
    document.getElementById("file-multiple-input").value = "";
    document.getElementById("image_show").innerHTML = "";
    setError(null);
  };
  const handleRemoveVariant = (index) => {
    setVariants(variants.filter((v, i) => i != index));
  };
  return (
    <>
      <AppLayout active={props.active}>
        <div className="main-content">
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="overview-wrap">
                    <h2 className="title-1 mx- 3">Update Product</h2>
                  </div>
                  <div className="col-lg-11 mt-3">
                    <div className="card">
                      <div className="card-header">
                        <strong>Update Product </strong>
                      </div>
                      <div className="card-body card-block">
                        <form
                          action
                          method="post"
                          encType="multipart/form-data"
                          className="form-horizontal"
                        >
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="text-input"
                                className=" form-control-label"
                              >
                                Name
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                value={productName}
                                onChange={ProductNameHandler}
                                type="text"
                                id="text-input"
                                name="text-input"
                                placeholder="Enter Product Name"
                                className="form-control"
                              />
                              <div className="text-danger">
                                {showError("name", error)}
                              </div>
                            </div>
                          </div>
                          {/* SELECT BOX CATEGORY  */}
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="select"
                                className=" form-control-label"
                              >
                                Select Category
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <select
                                name="select"
                                id="select"
                                className="form-control"
                                onChange={(event) => {
                                  setSelectedCategoryId(event.target.value);
                                }}
                              >
                                <option>Please Select</option>
                                {categories.map((category) => {
                                  return (
                                    <option
                                      key={category.id}
                                      value={category.id}
                                      selected={
                                        selectedCategoryId === category.id
                                          ? true
                                          : false
                                      }
                                    >
                                      {category.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="textarea-input"
                                className=" form-control-label"
                              >
                                Description
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <textarea
                                value={productDescription}
                                onChange={ProductDescriptionHandler}
                                name="textarea-input"
                                id="textarea-input"
                                rows="9"
                                placeholder="Enter Product Description"
                                className="form-control"
                              ></textarea>

                              <div className="text-danger">
                                {showError("description", error)}
                              </div>
                            </div>
                          </div>

                          {/* description end  */}
                          {/* PRODUCT DETAILS  */}
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="textarea-input"
                                className=" form-control-label"
                              >
                                Product Details
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <CKEditor
                                editor={ClassicEditor}
                                data={productDetails}
                                // onReady={(editor) => {
                                //   You can store the "editor" and use when it is needed.
                                //     console.log(
                                //       "Editor is ready to use!",
                                //       editor
                                //     );
                                // }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setProductDetails(data);
                                }}
                                // onBlur={(event, editor) => {
                                //   console.log("Blur.", editor);
                                // }}
                                // onFocus={(event, editor) => {
                                //   console.log("Focus.", editor);
                                // }}
                              />
                              <div className="text-danger">
                                {showError("product_detail", error)}
                              </div>
                            </div>
                          </div>

                          {/* RADIO  BUTTONS  */}

                          <div className="d-flex justify-content-sm-around mx-auto mt-4">
                            {/* FEATURED RADIO  */}
                            <div className="row form-group">
                              <div className="col col-md-3">
                                <label className=" form-control-label">
                                  Featured
                                </label>
                              </div>
                              <div className="col col-md-9">
                                <div className="form-check-inline form-check mx-sm-4">
                                  <label
                                    htmlFor="inline-radio1"
                                    className="form-check-label "
                                  >
                                    <input
                                      value="yes"
                                      checked={
                                        featured === "yes" ? true : false
                                      }
                                      onChange={featuredRadioChangeHandler}
                                      type="radio"
                                      id="inline-radio1"
                                      name="inline-radio-featured"
                                      className="form-check-input"
                                    />
                                    Yes
                                  </label>
                                  <label
                                    htmlFor="inline-radio2"
                                    className="form-check-label mx-3"
                                  >
                                    <input
                                      value="no"
                                      checked={featured === "no" ? true : false}
                                      onChange={featuredRadioChangeHandler}
                                      type="radio"
                                      id="inline-radio2"
                                      name="inline-radio-featured-2"
                                      className="form-check-input"
                                    />
                                    No
                                  </label>
                                </div>
                                <div className="text-danger">
                                  {showError("featured", error)}
                                </div>
                              </div>
                            </div>

                            {/* AVAILABLE RADIO  */}
                            <div className="row form-group">
                              <div className="col col-md-3">
                                <label className=" form-control-label">
                                  Available one
                                </label>
                              </div>
                              {/* heree */}
                              <div className="col col-md-9">
                                <div className="form-check-inline form-check mx-sm-4">
                                  <label
                                    htmlFor="inline-radio3"
                                    className="form-check-label "
                                  >
                                    <input
                                      value="yes"
                                      checked={
                                        available === "yes" ? true : false
                                      }
                                      onChange={(e) =>
                                        availableRadioChangeHandler(e)
                                      }
                                      type="radio"
                                      id="inline-radio3"
                                      name="inline-radios3"
                                      className="form-check-input"
                                    />
                                    Yes
                                  </label>
                                  <label
                                    htmlFor="inline-radio4"
                                    className="form-check-label mx-3"
                                  >
                                    <input
                                      value="no"
                                      checked={
                                        available === "no" ? true : false
                                      }
                                      onChange={(e) =>
                                        availableRadioChangeHandler(e)
                                      }
                                      type="radio"
                                      id="inline-radio4"
                                      name="inline-radios4"
                                      className="form-check-input"
                                    />
                                    No
                                  </label>
                                </div>
                                <div className="text-danger">
                                  {showError("available", error)}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* RADIO BUTTONS END  */}
                          {variants.map((variant, index) => {
                            return (
                              <div
                                key={variant.id}
                                className=" shadow-sm p-3 my-3 border rounded"
                              >
                                {index == 0 ? null : (
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm position-absolute m-2"
                                    style={{ top: 0, right: 0 }}
                                    onClick={() => {
                                      handleRemoveVariant(index);
                                    }}
                                  >
                                    &times;
                                  </button>
                                )}
                                <div className="d-flex mx-auto mt-3 ">
                                  {/* FEATURED RADIO  */}
                                  <div className="row form-group">
                                    <div className="col col-md-3">
                                      <label className=" form-control-label">
                                        Featured
                                      </label>
                                    </div>
                                    <div className="col col-md-9">
                                      <div className="form-check-inline form-check mx-sm-4">
                                        <label
                                          htmlFor={"inline-radio-fy_" + index}
                                          className="form-check-label "
                                        >
                                          <input
                                            value="yes"
                                            onChange={(event) => {
                                              variantInputsChangeHandler(
                                                "yes",
                                                index,
                                                "featured"
                                              );
                                            }}
                                            checked={
                                              variant.featured === "yes"
                                                ? true
                                                : false
                                            }
                                            type="radio"
                                            id={"inline-radio-fy_" + index}
                                            name={"inline-radios-fy_" + index}
                                            className="form-check-input"
                                          />
                                          Yes
                                        </label>
                                        <label
                                          htmlFor={"inline-radio-fn_" + index}
                                          className="form-check-label mx-3"
                                        >
                                          <input
                                            value="no"
                                            onChange={(event) => {
                                              variantInputsChangeHandler(
                                                "no",
                                                index,
                                                "featured"
                                              );
                                            }}
                                            checked={
                                              variant.featured === "no"
                                                ? true
                                                : false
                                            }
                                            type="radio"
                                            id={"inline-radio-fn_" + index}
                                            name={"inline-radios-fn_" + index}
                                            className="form-check-input"
                                          />
                                          No
                                        </label>
                                      </div>
                                      <div className="text-danger">
                                        {showError("featured", error)}
                                      </div>
                                    </div>
                                  </div>
                                  {/* AVAILABLE RADIO  */}
                                  <div className="row form-group">
                                    <div className="col col-md-3">
                                      <label className=" form-control-label">
                                        Available two
                                      </label>
                                    </div>
                                    <div className="col col-md-9">
                                      <div className="form-check-inline form-check mx-sm-4">
                                        <label
                                          htmlFor={"inline-radio-ay_" + index}
                                          className="form-check-label "
                                        >
                                          <input
                                            value="yes"
                                            checked={
                                              variant.available === "yes"
                                                ? true
                                                : false
                                            }
                                            onChange={(event) => {
                                              variantInputsChangeHandler(
                                                "yes",
                                                index,
                                                "available"
                                              );
                                            }}
                                            type="radio"
                                            id={"inline-radio-ay_" + index}
                                            name={"inline-radios-ay_" + index}
                                            className="form-check-input"
                                          />
                                          Yes
                                        </label>
                                        <label
                                          htmlFor={"inline-radio-an_" + index}
                                          className="form-check-label mx-3"
                                        >
                                          <input
                                            value="no"
                                            checked={
                                              variant.available === "no"
                                                ? true
                                                : false
                                            }
                                            onChange={(event) => {
                                              variantInputsChangeHandler(
                                                "no",
                                                index,
                                                "available"
                                              );
                                            }}
                                            type="radio"
                                            id={"inline-radio-an_" + index}
                                            name={"inline-radios-an_" + index}
                                            className="form-check-input"
                                          />
                                          No
                                        </label>
                                      </div>
                                      <div className="text-danger">
                                        {showError("available", error)}
                                      </div>
                                    </div>
                                  </div>
                                  {/* DISCOUNT RADIO  */}
                                  <div className="row form-group">
                                    <div className="col col-md-3">
                                      <label className=" form-control-label">
                                        Discount
                                      </label>
                                    </div>
                                    <div className="col col-md-9">
                                      <div className="form-check-inline form-check mx-sm-4">
                                        <label
                                          htmlFor={"inline-radio-dy_" + index}
                                          className="form-check-label "
                                        >
                                          <input
                                            value="yes"
                                            checked={
                                              variant.discount === "yes"
                                                ? true
                                                : false
                                            }
                                            onChange={(event) => {
                                              variantInputsChangeHandler(
                                                "yes",
                                                index,
                                                "discount"
                                              );
                                            }}
                                            type="radio"
                                            id={"inline-radio-dy_" + index}
                                            name={"inline-radios-dy_" + index}
                                            className="form-check-input"
                                          />
                                          Yes
                                        </label>
                                        <label
                                          htmlFor={"inline-radio-dn_" + index}
                                          className="form-check-label mx-3"
                                        >
                                          <input
                                            value="no"
                                            checked={
                                              variant.discount === "no"
                                                ? true
                                                : false
                                            }
                                            onChange={(event) => {
                                              variantInputsChangeHandler(
                                                "no",
                                                index,
                                                "discount"
                                              );
                                            }}
                                            type="radio"
                                            id={"inline-radio-dn_" + index}
                                            name={"inline-radios-dn_" + index}
                                            className="form-check-input"
                                          />
                                          No
                                        </label>
                                      </div>
                                      <div className="text-danger">
                                        {showError("discount", error)}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row d-flex justify-content-between ">
                                  {/* PRICE INPUT  */}
                                  <div className="form-group col-md-6">
                                    <div className="col-12 ">
                                      <label
                                        htmlFor="text-input"
                                        className=" form-control-label"
                                      >
                                        Price
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-12">
                                      <input
                                        value={variant?.price}
                                        onChange={(event) => {
                                          variantInputsChangeHandler(
                                            event?.target?.value,
                                            index,
                                            "price"
                                          );
                                        }}
                                        type="number"
                                        id="number-input"
                                        name="number-input"
                                        min="0.1"
                                        step="0.1"
                                        placeholder="Price"
                                        className="form-control"
                                      />
                                      <div className="text-danger">
                                        {showError("price", error)}
                                      </div>
                                    </div>
                                  </div>

                                  {/* SELECT BOX SIZE */}
                                  <div className="form-group col-12 col-md-6">
                                    <div className="col-12">
                                      <label
                                        htmlFor="select"
                                        className=" form-control-label pl-3"
                                      >
                                        Sizes
                                      </label>
                                      <div className="col-12 col-md-12">
                                        <select
                                          name="select"
                                          id="select"
                                          className="form-control"
                                          onChange={(event) => {
                                            variantInputsChangeHandler(
                                              event?.target?.value,
                                              index,
                                              "size_id"
                                            );
                                          }}
                                          // onChange={(event) => {
                                          //   setSelectedSizeId(event.target.value);
                                          // }}
                                        >
                                          <option>Please Select</option>
                                          {sizes.map((size) => {
                                            return (
                                              <option
                                                key={size.id}
                                                value={size.id}
                                                selected={
                                                  variant.size_id === size.id
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {size.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </div>

                                  {variant?.discount === "yes" ? (
                                    <div className="form-group col-md-6 ">
                                      <div className="col-12">
                                        <label
                                          htmlFor="text-input"
                                          className=" form-control-label "
                                        >
                                          Discounted Price
                                        </label>
                                      </div>
                                      <div className="col-12 ">
                                        <input
                                          value={variant?.discounted_price}
                                          onChange={(event) => {
                                            variantInputsChangeHandler(
                                              event?.target?.value,
                                              index,
                                              "discounted_price"
                                            );
                                          }}
                                          type="number"
                                          id="number-input"
                                          name="number-input"
                                          step="0.1"
                                          min="0.1"
                                          placeholder="Discounted Price"
                                          className="form-control"
                                        />
                                        <div className="text-danger">
                                          {showError("discount", error)}
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}

                                  {/* <button
                                    className="btn btn-sm btn-primary mx-5"
                                    type="button"
                                    onClick={saveVariant}
                                  >
                                    Save Variant
                                  </button> */}
                                </div>
                              </div>
                            );
                          })}
                          <button
                            type="button"
                            onClick={variantHandler}
                            className="btn btn-sm btn-primary mt-2"
                          >
                            Add Variants
                          </button>
                        </form>
                      </div>

                      {/* PRODUCT IMAGES  */}
                      <div className="row form-group">
                        <div className="col col-md-3">
                          <div className="ml-4">
                            <label
                              htmlFor="file-multiple-input"
                              className=" form-control-label "
                            >
                              Upload Images:
                            </label>
                            <input
                              type="file"
                              id="file-multiple-input"
                              name="file-multiple-input"
                              multiple
                              className="form-control-file"
                              onChange={(e) => imageChangeHandler(e)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-9">
                          <div className="text-danger">
                            {showError("image", error)}
                          </div>
                          <div className="row">
                            {productImage.map((image, index) => {
                              return (
                                <>
                                  <div
                                    className="mx-2 w-25 h-50 col-4"
                                    style={{ position: "relative" }}
                                  >
                                    <img
                                      id="image_show"
                                      multiple
                                      alt="preview"
                                      src={
                                        image ? URL.createObjectURL(image) : ""
                                      }
                                    />
                                    <button
                                      onClick={() => {
                                        deleteNewlyStoredImage(index);
                                      }}
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                        background: "red",
                                        color: "white",
                                        fontSize: "14px",
                                        borderRadius: "50%",
                                        width: "20px",
                                        height: "20px",
                                        textAlign: "center",
                                        margin: "5px",
                                        lineHeight: "20px",
                                      }}
                                    >
                                      X
                                    </button>
                                  </div>
                                </>
                              );
                            })}

                            {alreadyStoredProductImage.map((image) => {
                              return (
                                <>
                                  <div
                                    className="mx-2 w-25 h-50 col-4"
                                    style={{ position: "relative" }}
                                  >
                                    <img
                                      id="image_show"
                                      multiple
                                      alt="preview"
                                      src={image.img}
                                    />
                                    <button
                                      onClick={() => {
                                        deleteAlreadyStoredImage(image.id);
                                      }}
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                        background: "red",
                                        color: "white",
                                        fontSize: "14px",
                                        borderRadius: "50%",
                                        width: "20px",
                                        height: "20px",
                                        textAlign: "center",
                                        margin: "5px",
                                        lineHeight: "20px",
                                      }}
                                    >
                                      X
                                    </button>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end">
                        <button
                          type="reset"
                          onClick={resetFormHandler}
                          className="btn btn-danger btn-sm mx-3"
                        >
                          <i className="fa fa-ban" /> Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm"
                          onClick={onSubmitNewProduct}
                        >
                          <i className="fa fa-dot-circle-o" /> Update Product
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default EditProduct;
