import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Dashboard from "./Pages/Dashboard";
import Categories from "./Pages/Categories/Categories";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import CreateCategory from "./Pages/Categories/CreateCategory";
import EditCategory from "./Pages/Categories/EditCategory";
import Products from "./Pages/Products/Products";
import Createproduct from "./Pages/Products/CreateProduct";
import CreateSizes from "./Pages/Sizes/CreateSizes";
import Sizes from "./Pages/Sizes/Sizes";
import EditSizes from "./Pages/Sizes/EditSizes";
import EditProduct from "./Pages/Products/EditProduct";
import Settings from "./Pages/Settings";
import Banners from "./Pages/Banners/Banners";
import CreateBanner from "./Pages/Banners/CreateBanner";
import Orders from "./Pages/Orders";
import TestDrop from "./Components/TestDrop";
import OrderDetailPage from "./Components/OrderDetailPage";
import Users from "./Pages/Users/Users";
import Coupons from "./Pages/Coupons/Coupons";
import CreateCoupon from "./Pages/Coupons/CreateCoupon";
const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>

          <Route exact path="/orderDetail/:id">
            <OrderDetailPage />
          </Route>
          <Route exact path="/dashboard">
            <Dashboard active="dashboard" />
          </Route>
          <Route exact path="/forgotpassword">
            <ForgotPassword />
          </Route>
          <Route exact path="/categories">
            <Categories active="categories" />
          </Route>
          <Route exact path="/createcategory">
            <CreateCategory active="categories" />
          </Route>
          <Route exact path="/categories/:id/edit">
            <EditCategory active="categories" />
          </Route>
          <Route exact path="/products">
            <Products active="products" />
          </Route>
          <Route exact path="/products/:id/edit">
            <EditProduct active="products" />
          </Route>
          <Route exact path="/createproduct">
            <Createproduct active="products" />
          </Route>
          <Route exact path="/banners">
            <Banners active="banners" />
          </Route>
          <Route exact path="/createbanner">
            <CreateBanner active="banners" />
          </Route>
          <Route exact path="/sizes">
            <Sizes active="sizes" />
          </Route>
          <Route exact path="/createsizes">
            <CreateSizes active="sizes" />
          </Route>
          <Route exact path="/sizes/:id/edit">
            <EditSizes active="sizes" />
          </Route>
          <Route exact path="/orders">
            <Orders active="orders" />
          </Route>
          <Route exact path="/settings">
            <Settings active="settings" />
          </Route>
          <Route exact path="/users">
            <Users active="users" />
          </Route>

          <Route exact path="/coupons">
            <Coupons active="coupons" />
          </Route>
          <Route exact path="/coupons/create">
            <CreateCoupon active="coupons" />
          </Route>
          <Route exact path="/test">
            <TestDrop active="test" />
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default App;
