/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import AppLayout from "../Components/AppLayout";
import FullScreenLoader from "../Components/FullScreenLoader";
import {
  API,
  CANCELLED,
  confirmation,
  dateFunc,
  DELEVIRED,
  FAILED_DELIVERY,
  findItem,
  PENDING,
  READY_TO_SHIP,
  RETURNED,
  SHIPPED,
} from "../Components/Helper";
import {
  changeOrderStatus,
  getOrdersCall,
  getOrderStatuses,
} from "../Components/Repository";

const Orders = (props) => {

  const [isLoading,setIsLoading] = useState(false)
  let cols = [
    {
      name: "Order Date",
      selector: (row) => row?.created_at,
      format: (row) => {
        let date = dateFunc(row?.created_at);
        return (
          <>
            <div> {date.date} </div>
            <div className="text-primary"> {date.time} </div>
          </>
        );
      },
    },

    {
      name: "Order Status",
      selector: (row) => row?.status?.name,
      format: (row) => {
        return (
          <>
            <div>{row?.status?.name}</div>
          </>
        );
      },
    },
    {
      name: "Total",
      selector: (row) => row?.total,
      format: (row) => {
        return (
          <>
            <div> Rs. {row?.total} </div>
          </>
        );
      },
    },
    {
      name: "Action",
      selector: (row) => row,
      format: (row) => {
        return (
          <>
            <div className="d-flex justify-content-start">
              <div
                onClick={() => {
                  detailButtonHandler(row.id);
                }}
                className="d-flex justify-content-center text-info btn"
              >
                <i className="zmdi zmdi-eye "></i>
              </div>
              {showOrderActions(row)}
            </div>
          </>
        );
      },
    },
  ];

  const detailButtonHandler = (id) => {
    // history.push("/categories/" + id + "/edit");
    history.push("/orderDetail/" + id);
  };

  const history = useHistory();

  const [columns, setColumns] = useState(cols);

  const [ordersStatusData, setOrdersStatusData] = useState([]);

  const [orderData, setOrderData] = useState();

  const [isAll, setIsAll] = useState(true);

  const [activeId, setActiveId] = useState("");

  useEffect(() => {
    if (!isAll) {
      let newArr = cols.filter((cl) => cl?.name !== "Order Status");
      setColumns(newArr);
    } else {
      setColumns(cols);
    }
  }, [isAll, ordersStatusData]);

  useEffect(() => {
    getStatuses();
    getOrdersHandler(null, true);
  }, []);

  const getOrdersHandler = async (id, isAll = false) => {
    setIsAll(isAll);
    let data = {};
    if (id)
      data.status_id = ordersStatusData?.find(
        (status) => status?.id === id
      )?.id;
    if (isAll) data.all = 1;
    //CALL ORDER
    let response = await getOrdersCall(data);
    setOrderData(response?.data?.data);
  };

  const changeStatusHandler = (row, statusId) => {
    let data = {};
    setIsLoading(true)

    // data.status_id = 1;
    data.id = row.id;
    data.status_id = statusId;
    changeOrderStatus(
      data,
      changeOrderStatusSuccessCallback,
      changeOrderStatusErrorCallback
    );
  };

  const activeIdHandler = (id) => {
    setActiveId(id);
  };

  const changeOrderStatusSuccessCallback = () => {
  setIsLoading(false)

    getOrdersHandler(isAll === false ? activeId : null, isAll ? true : false);
  };

  const changeOrderStatusErrorCallback = (e) => {
    setIsLoading(false)
  };

  const getStatuses = () => {
    getOrderStatuses(setOrdersStatusData);
  };

  const showOrderActions = (row) => {
    switch (row?.status?.name) {
      case PENDING:
        return (
          <div>
            <button

              onClick={() => confirmation(() => {
                changeStatusHandler(
                  row,
                  findItem(ordersStatusData, "name", READY_TO_SHIP)?.id
                )
              })}


              className="btn btn-sm btn-success mx-1"
            >
              Accept
            </button>
            <button
              onClick={() => confirmation(() => {
                changeStatusHandler(
                  row,
                  findItem(ordersStatusData, "name", CANCELLED)?.id
                );
              })}

              className="btn btn-sm btn-danger mx-1"
            >
              <strong>X</strong>
            </button>
          </div>
        );
        break;
      case READY_TO_SHIP:
        // code block
        return (
          <div>
            <button
              onClick={() => confirmation(() => {
                changeStatusHandler(
                  row,
                  findItem(ordersStatusData, "name", SHIPPED)?.id
                );
              })}

              className="btn btn-sm btn-success mx-1"
            >
              Shipped
            </button>
            <button
              onClick={() => confirmation(() => {
                changeStatusHandler(
                  row,
                  findItem(ordersStatusData, "name", CANCELLED)?.id
                );
              })}

              className="btn btn-sm btn-danger mx-1"
            >
              <strong>X</strong>
            </button>
          </div>
        );
        break;
      case SHIPPED:
        return (
          <div>
            <button
              onClick={() => confirmation(() => {
                changeStatusHandler(
                  row,
                  findItem(ordersStatusData, "name", DELEVIRED)?.id
                );
              })}

              className="btn btn-sm btn-success mx-1"
            >
              Delivered
            </button>
            <button
              onClick={() => confirmation(() => {
                changeStatusHandler(
                  row,
                  findItem(ordersStatusData, "name", FAILED_DELIVERY)?.id
                );
              })}

              className="btn btn-sm btn-danger mx-1"
            >
              Failed Delivery
            </button>
          </div>
        );
        break;
      case DELEVIRED:
        return (
          <div>
            <button
              onClick={() => confirmation(() => {
                changeStatusHandler(
                  row,
                  findItem(ordersStatusData, "name", RETURNED)?.id
                );
              })}
           
              className="btn btn-sm btn-danger mx-1"
            >
              Returned
            </button>
          </div>
        );
        break;
      case CANCELLED:
        return <div>---</div>;
        break;
      case RETURNED:
        return <div>---</div>;
        break;
      case FAILED_DELIVERY:
        return (
          <div>
            <button
             onClick={() => confirmation(() => {
              changeStatusHandler(
                row,
                findItem(ordersStatusData, "name", READY_TO_SHIP)?.id
              );
            })}
            
              className="btn btn-sm btn-success mx-1"
            >
              Ready To Ship Again
            </button>
          </div>
        );
        break;

      default:
    }
  };

  return (
    <>
      <AppLayout active={props.active}>
        <div className="main-content">
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12"></div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className=" d-flex justify-content-between">
              <h2 className="title-1 my-3">Orders</h2>
            </div>
          </div>
          <div className="col-lg-12 mt-3">
            <div className="card">
              <div className="card-header">
                <strong>Order Status</strong>
              </div>
              <div className="card-body card-block">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a
                      onClick={() => {
                        getOrdersHandler(null, true);
                      }}
                      className={
                        isAll === true ? "nav-link active" : "nav-link"
                      }
                      href="#/"
                    >
                      <strong className="text-info"> All </strong>
                    </a>
                  </li>

                  {ordersStatusData?.map((status) => {
                    return (
                      <li className="nav-item">
                        <a
                          onClick={() => {
                            getOrdersHandler(status.id);
                            activeIdHandler(status.id);
                          }}
                          href="#/"
                          className={
                            (activeId === status.id) & (isAll === false)
                              ? "active nav-link"
                              : "nav-link"
                          }
                        >
                          <strong className="text-info">{status?.name}</strong>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {ordersStatusData.length ? (
              <DataTable columns={columns} data={orderData} pagination />
            ) : null}

            {isLoading ?<FullScreenLoader/>:null }
            
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default Orders;
