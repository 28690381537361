import React from "react";

const TestDrop = () => {
  return (
    <>
      <div className="col col-md-12">
        <div className="input-group">
          <div className="input-group-btn">
            <div className="btn-group">
              <button
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="dropdown-toggle btn btn-success"
              >
                Action
              </button>
              <div
                tabIndex={-1}
                aria-hidden="true"
                role="menu"
                className="dropdown-menu"
                x-placement="top-start"
                style={{
                  position: "absolute",
                  transform: "translate3d(0px, -173px, 0px)",
                  top: "0px",
                  left: "0px",
                  willChange: "transform",
                }}
              >
                <button type="button" tabIndex={0} className="dropdown-item">
                  Action
                </button>
                <button type="button" tabIndex={0} className="dropdown-item">
                  Another Action
                </button>
                <button type="button" tabIndex={0} className="dropdown-item">
                  Something else here
                </button>
                <div tabIndex={-1} className="dropdown-divider" />
                <button type="button" tabIndex={0} className="dropdown-item">
                  Separated link
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestDrop;
