/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
const DesktopNavbar = () => {
  const history = useHistory();

  const [user, setUser] = useState(null);
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "js/main.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const logoutHandler = () => {
    localStorage.clear();
    history.push("/");
  };

  const settingsHandler = () => {
    history.push("/settings");
  };
  return (
    <>
      {/* Desktop Header Start  */}
      <header className="header-desktop">
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="header-wrap">
              <form className="form-header" method="POST">
              </form>
              {/* BOOTSTRAP DROPDOWN START  */}

              <div className="dropdown header-button">
                <div className="account-wrap">
                  <a
                    href="#/"
                    className="btn"
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="account-item clearfix px-2">
                      <div className="image">
                        <img src="images/icon/avatar-01.jpg" alt="John Doe" />
                      </div>
                      <div className="content text-dark">
                        <span className="js-acc-btn">{user?.name}</span>
                        <i className="zmdi zmdi-chevron-down ml-2" />
                      </div>
                    </div>
                  </a>
                  <div
                    className="dropdown-menu dropdown_menu_css"
                    aria-labelledby="dropdownMenu1"
                  >
                    <div className="account-item clearfix dropdown_item_css">
                      <div className="image">
                        <img src="images/icon/avatar-01.jpg" alt="John Doe" />
                      </div>
                      <div className="content dropdown_content_css">
                        <h5 className="name">
                          <a href="#/"> {user?.name}</a>
                        </h5>
                        <span className="email">{user?.email}</span>
                      </div>
                    </div>
                    <div
                      onClick={settingsHandler}
                      className="account-dropdown__item"
                    >
                      <a href="#/">
                        <i className="zmdi zmdi-settings " />
                        Setting
                      </a>
                    </div>
                    <div
                      onClick={logoutHandler}
                      className="account-dropdown__footer"
                    >
                      <div className="account-dropdown__item">
                        <a href="#/">
                          <i className="zmdi zmdi-power" />
                          Log Out
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="header-button">
                <div className="account-wrap">
                  <div className="account-item clearfix js-item-menu">
                    <div className="image">
                      <img src="images/icon/avatar-01.jpg" alt="John Doe" />
                    </div>
                    <div className="content">
                      <a className="js-acc-btn" href="#/">
                        {user?.name}
                      </a>
                    </div>
                    <div className="account-dropdown js-dropdown">
                      <div className="info clearfix">
                        <div className="image">
                          <a href="#/">
                            <img
                              src="images/icon/avatar-01.jpg"
                              alt="John Doe"
                            />
                          </a>
                        </div>
                        <div className="content">
                          <h5 className="name">
                            <a href="#/"> {user?.name}</a>
                          </h5>
                          <span className="email">johndoe@example.com</span>
                        </div>
                      </div>
                      <div className="account-dropdown__body">
                        <div className="account-dropdown__item">
                          <a href="#/">
                            <i className="zmdi zmdi-account" />
                            Account
                          </a>
                        </div>
                        <div className="account-dropdown__item">
                          <a href="#/">
                            <i className="zmdi zmdi-settings" />
                            Setting
                          </a>
                        </div>
                        <div className="account-dropdown__item">
                          <a href="#/">
                            <i className="zmdi zmdi-money-box" />
                            Billing
                          </a>
                        </div>
                      </div>

                      <div className="account-dropdown__footer">
                        <div className="account-dropdown__item">
                          <a href="#/">
                            <i className="zmdi zmdi-power" />
                            Log Out
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default DesktopNavbar;
