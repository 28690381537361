import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AppLayout from "../../Components/AppLayout";
import { API, showError } from "../../Components/Helper";
import { storeHomeBannerCall } from "../../Components/Repository";

const CreateBanner = (props) => {
  const history = useHistory();

  const [productImage, setProductImage] = useState([]);

  const [error, setError] = useState();

  const onSubmitNewBanner = async () => {
    setError(null);
    let data = new FormData();
    productImage.forEach((img) => {
      data.append(`image`, img);
    });
    try {
      let response = storeHomeBannerCall(data);
      setProductImage([]);
      history.push("/banners");
    } catch (error) {
      setError(error);
    }
  };

  const imageChangeHandler = (event) => {
    setProductImage([...productImage, ...event.target.files]);
  };

  const resetFormHandler = () => {
    document.getElementById("file-multiple-input").value = "";
    document.getElementById("image_show").innerHTML = "";
    setProductImage([]);
    setError(null);
  };
  return (
    <>
      <AppLayout active={props.active}>
        <div className="main-content">
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="overview-wrap">
                    <h2 className="title-1 mx-3">Add a New Home Banner</h2>
                  </div>
                  <div className="col-lg-11 mt-3">
                    <div className="card">
                      <div className="card-header">
                        <strong>Home Banners</strong>
                      </div>
                      <div className="card-body card-block">
                        <form
                          method="post"
                          encType="multipart/form-data"
                          className="form-horizontal"
                        >
                          {/* IMAGES UPLOAD  */}
                          <div className="row form-group">
                            <div className="col col-md-3">
                              <label
                                htmlFor="file-multiple-input"
                                className=" form-control-label"
                              >
                                Upload Images
                              </label>
                            </div>
                            <div className="col-12 col-md-9">
                              <input
                                type="file"
                                id="file-multiple-input"
                                name="file-multiple-input"
                                multiple
                                className="form-control-file"
                                onChange={(e) => imageChangeHandler(e)}
                              />
                              {/* <small className="text-muted">
                                **image size should be 270x270 pixels
                              </small> */}
                              <div className="text-danger">
                                {showError("image", error)}
                              </div>
                              <div className="d-flex justify-content-evenly">
                                {productImage.map((image) => {
                                  // console.log("sadsda", productImage);
                                  return (
                                    <img
                                      className="mx-2 w-25 h-25"
                                      id="image_show"
                                      multiple
                                      alt="img"
                                      src={
                                        image ? URL.createObjectURL(image) : ""
                                      }
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="card-footer d-flex justify-content-end">
                        <button
                          type="reset"
                          onClick={resetFormHandler}
                          className="btn btn-danger btn-sm mx-3"
                        >
                          <i className="fa fa-ban" /> Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm"
                          onClick={onSubmitNewBanner}
                        >
                          <i className="fa fa-dot-circle-o" /> Add New Banner
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default CreateBanner;
