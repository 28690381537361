import React, { useEffect, useState } from "react";
import AppLayout from "../../Components/AppLayout";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { API, confirmation } from "../../Components/Helper";
import {
  deleteProductCall,
  getProductsCall,
} from "../../Components/Repository";
// import ReactHtmlParser from "react-html-parser";

const Products = (props) => {
  const [message, setMessage] = useState("");

  const columns = [
    // {
    //   name: "#",
    //   selector: (row, index) => index + 1,
    // },
    {
      name: "Name",
      selector: (row) => row?.name,
      format: (row) => {
        return (
          <>
            <div> {row.name} </div>
          </>
        );
      },
    },
    // {
    //   name: "Name",
    //   selector: (row) => row.name,
    // },
    {
      name: "Category",
      selector: (row) => row?.category?.name,
    },
    // {
    //   name: "Available",
    //   selector: (row) => {
    //     if (row.available) {
    //       return "yes";
    //     } else {
    //       return "No";
    //     }
    //   },
    // },
    // {
    //   name: "Description",
    //   selector: (row) => row.description,
    // },
    // {
    //   name: "Product Details",
    //   selector: (row) => row.product_detail,
    //   format: (row) => {
    //     return (
    //       <>
    //         <div> {ReactHtmlParser(row?.product_detail)} </div>
    //       </>
    //     );
    //   },
    // },
    {
      name: "Image",
      selector: (row) => row?.featured_image?.thumbnail,
      format: (row) => {
        return (
          <>
            <img src={row?.featured_image?.thumbnail} alt="thumbnail" />
          </>
        );
      },
    },

    {
      name: "Featured",
      selector: (row) => {
        if (row.featured) {
          return "yes";
        } else {
          return "No";
        }
      },
    },
    {
      name: "Price",
      selector: (row) =>
        row?.variants?.map((variant) => variant.price).join(","),
    },
    {
      name: "Discounted Price",
      selector: (row) =>
        row?.variants?.map((variant) => variant.discounted_price).join(","),
    },
    // {
    //   name: "Discount",
    //   selector: (row) => {
    //     if (row.discount) {
    //       return "yes";
    //     } else {
    //       return "No";
    //     }
    //   },
    // },
    // {
    //
    {
      name: "Size",
      selector: (row) =>
        row?.variants?.map((variant) => variant?.size?.name).join(","),
    },

    {
      cell: (row) => (
        <div className="d-flex table-data-feature">
          {/*  */}
          <button
            onClick={() =>
              confirmation(() => {
                deleteProductHandler(row.id);
              })
            }
            className="item"
          >
            <i className="zmdi zmdi-delete text-danger" />
          </button>
          <button
            onClick={() => editProductHandler(row.id)}
            className="item mx-3"
          >
            <i className="zmdi zmdi-edit text-primary" />
          </button>
        </div>
      ),
    },
  ];
  const history = useHistory();

  const [productData, setproductData] = useState();

  const getDataCall = async () => {
    let response = await getProductsCall();
    setproductData(response.data.products);
  };
  const createproductHandler = () => {
    history.push("/createproduct");
  };

  useEffect(() => {
    getDataCall();
  }, []);

  const deleteProductHandler = async (id) => {
    let response = await deleteProductCall(id);
    getDataCall();
    setMessage(response.data.message);
  };

  const deleteNotification = message ? (
    <div class="alert alert-success" role="alert">
      {message}
    </div>
  ) : null;

  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);

  const editProductHandler = (id) => {
    history.push("/products/" + id + "/edit");
  };

  return (
    <>
      <AppLayout active={props.active}>
        <div className="main-content">
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="overview-wrap">{deleteNotification}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid px-5">
            <div className=" d-flex justify-content-between">
              <h2 className="title-1 my-3">Products</h2>
              <button
                onClick={createproductHandler}
                className="mx-5 text-success"
              >
                <i className="zmdi zmdi-plus-circle mx-2" />
                Add A New Product
              </button>
            </div>
            <DataTable columns={columns} data={productData} pagination />
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default Products;
