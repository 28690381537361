import React from "react";
// import { useParams } from "react-router-dom";
import DesktopNavbar from "./DesktopNavbar";
import Footer from "./Footer";
import MobileNavbar from "./MobileNavbar";
import Sidebar from "./Sidebar";

const AppLayout = (props) => {
  return (
    <>
      <div className="page-wrapper">
        <Sidebar active={props.active} />
        <MobileNavbar />
        <div className="page-container">
          <DesktopNavbar userName={props.userName} />
          {props.children}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AppLayout;
