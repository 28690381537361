import React, { useEffect, useState } from "react";
import AppLayout from "../Components/AppLayout";
import { getDashboardDetailsCall } from "../Components/Repository";

const Dashboard = (props) => {
  const [data, setData] = useState(null);
  const getDashboardDetails = async () => {
    const response = await getDashboardDetailsCall();
    setData(response.data);
  };
  useEffect(() => {
    getDashboardDetails();
  }, []);
  return (
    <>
      <AppLayout active={props.active}>
        <div className="main-content">
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="overview-wrap">
                    <h2 className="title-1">Dashboard</h2>
                  </div>
                </div>
              </div>
              <div className="row m-t-25">
                <div className="col-sm-6 col-lg-3">
                  <div className="overview-item overview-item--c1">
                    <div className="overview__inner">
                      <div className="overview-box clearfix">
                        <div className="icon">
                          <i className="zmdi zmdi-account-o" />
                        </div>
                        <div className="text">
                          <h2>{data?.total_users}</h2>
                          <span>users</span>
                        </div>
                      </div>
                      <div className="overview-chart">
                        <canvas id="widgetChart1" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="overview-item overview-item--c2">
                    <div className="overview__inner">
                      <div className="overview-box clearfix">
                        <div className="icon">
                          <i className="zmdi zmdi-shopping-cart" />
                        </div>
                        <div className="text">
                          <h2>{data?.total_orders}</h2>
                          <span>total orders</span>
                        </div>
                      </div>
                      <div className="overview-chart">
                        <canvas id="widgetChart2" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                  <div className="overview-item overview-item--c4">
                    <div className="overview__inner">
                      <div className="overview-box clearfix">
                        <div className="icon">
                          {/* <i className="zmdi zmdi-money" /> */}
                        </div>
                        <div className="text">
                          <h2>RS {data?.total_money}</h2>
                          <span>total earnings</span>
                        </div>
                      </div>
                      <div className="overview-chart">
                        <canvas id="widgetChart4" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default Dashboard;
