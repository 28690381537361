/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppLayout from "../Components/AppLayout";
import { API, dateFunc } from "./Helper";
import { getOrderDetailCall } from "./Repository";

const OrderDetailPage = () => {
  const [detailsData, setDetailsData] = useState(null);

  const date = dateFunc(detailsData?.created_at);

  const { id } = useParams();

  
  const getDetails = async () => {
    try{
      let response = await  getOrderDetailCall(id);
    setDetailsData(response?.data.data);
    }catch(e){
      console.log(e)

    }
    
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <>
      <AppLayout>
        <div className="main-content">
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="overview-wrap">
                    <h2 className="title-1 mx-3">Order Summary</h2>
                  </div>
                  <div className="col-lg-11 mt-3 ">
                    <div className="card ">
                      <div className="card-header ">
                        <strong>Order Details</strong>
                      </div>
                      {/* CONTENT */}
                      <div className="row d-flex justify-content-start ">
                        <div className="col-12 mt-4">
                          <div className="d-flex justify-content-between m-3 text-dark row">
                            <strong className="col-3 ">Order Item</strong>
                            <span className="col-1 "></span>
                            <strong className="col-3 ">Quantity</strong>
                            <span className="col-1 "></span>
                            <strong className="col-3 ">Subtotal</strong>
                          </div>
                          <div className="d-flex justify-content-between m-3 row">
                            { detailsData?.items?.map((item) => {
                              return (
                                <>
                                  <div className="col-3 my-2">
                                    {item?.product?.name} -
                                    {item?.product_variant?.size?.name}
                                    <div>
                                      <small className="text-primary font-weight-bold">
                                        {item?.product?.category?.name}
                                      </small>
                                    </div>
                                  </div>
                                  <div className="col-1 my-2 font-weight-bold text-dark">
                                    x
                                  </div>
                                  <div className="col-3 my-2">
                                    {item?.quantity}
                                  </div>
                                  <div className="col-1 my-2 font-weight-bold text-dark">
                                    =
                                  </div>

                                  <div className="col-3 my-2">
                                    {item?.subtotal}
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                        {/* <div className="col-3 mt-4 pt-3">
                          <strong className="text-dark mx-2">
                            Order Status:
                          </strong>
                          <span className="text-info">
                            {detailsData?.status?.name}
                          </span>
                        </div> */}
                      </div>
                      <div className="d-flex justify-content-end mx-5 px-5 border-top my-3">
                        <div className="col-4 font-weight-bold ">
                          <div className="d-flex justify-content-between mt-3">
                            Subtotal :<span>{detailsData?.sub_total}</span>
                          </div>
                          <div className="d-flex justify-content-between border-bottom">
                            Delivery Charges :
                            <span>{detailsData?.delivery_charges}</span>
                          </div>
                          <div className="d-flex justify-content-between border-top">
                            Total :<span>{detailsData?.total}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <strong>Customer Details</strong>
                      </div>
                      {/* CONTENT */}
                      <div className="d-flex justify-content-between mx-3 mt-3 text-dark row">
                        <strong className="col-3 ">Customer Name</strong>
                        <strong className="col-3 ">Contact Number</strong>
                        <strong className="col-3 ">Email Address</strong>
                        <strong className="col-3 ">City</strong>
                      </div>
                      <div className="d-flex justify-content-between m-3 row">
                        <div className="col-3">
                          {detailsData?.user?.name}
                        </div>
                        <div className="col-3">
                          {detailsData?.user?.phone}
                        </div>
                        <div className="col-3">
                          {detailsData?.user?.email}
                        </div>
                        <div className="col-3">{detailsData?.city}</div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <strong>Delivery Details</strong>
                      </div>
                      {/* CONTENT */}
                      <div className="d-flex justify-content-between mx-3 mt-3 text-dark row">
                        <strong className="col-3 ">Time of Order</strong>
                        <strong className="col-3 ">Address</strong>
                        <strong className="col-3 ">City</strong>
                      </div>
                      <div className="d-flex justify-content-between m-3 row">
                        <div className="col-3">
                          <div>{date.date}</div>
                          <div>{date.time}</div>
                        </div>
                        <div className="col-3">
                          <div>{detailsData?.street_address},</div>
                          <div>{detailsData?.city}</div>
                        </div>
                        <div className="col-3">{detailsData?.city}</div>
                      </div>
                      {/* <div className="row d-flex justify-content-center">
                        <div className="col-11 ">
                          <ul className=" d-flex justify-content-between list-unstyled mx-2 my-4">
                            <li>
                              <strong className="text-dark">
                                Time of Order :
                              </strong>
                              <span>
                                <div>{date.date}</div>
                                <div>{date.time}</div>
                              </span>
                            </li>
                            <li>
                              <strong className="text-dark">Address :</strong>
                              <span className="mx-1">
                                <div>{detailsData?.street_address},</div>
                                <div>{detailsData?.city}</div>
                              </span>
                            </li>

                            <li>
                              <strong className="text-dark">City :</strong>
                              <span className="mx-1">
                                {detailsData?.city}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </div>
                    {detailsData?.order_notes ? (
                      <div className="card">
                        <div className="card-header">
                          <strong>NOTES</strong>
                        </div>
                        {/* CONTENT */}
                        <div className="d-flex justify-content-between mx-3 mt-3 text-dark row">
                          <strong className="col-3 ">Order Notes</strong>
                        </div>
                        <div className="d-flex justify-content-between m-3 row">
                          <div className="col-3">
                            {detailsData?.order_notes}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default OrderDetailPage;
