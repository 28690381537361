import React from "react";

const MobileNavbar = () => {
  return (
    <>
      {/* Mobile Header Start */}

      <header className="header-mobile d-block d-lg-none">
        <div className="header-mobile__bar">
          <div className="container-fluid">
            <div className="header-mobile-inner">
              <a className="logo" href="index.html">
                <img src="images/icon/logo.png" alt="CoolAdmin" />
              </a>
              <button className="hamburger hamburger--slider" type="button">
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
          </div>
        </div>
        <nav className="navbar-mobile">
          <div className="container-fluid">
            <ul className="navbar-mobile__list list-unstyled">
              <li className="has-sub">
                <a className="js-arrow" href="#/">
                  <i className="fas fa-tachometer-alt" />
                  Dashboard
                </a>
                {/* <ul className="navbar-mobile-sub__list list-unstyled js-sub-list">
                  <li>
                    <a href="index.html">Dashboard 1</a>
                  </li>
                  <li>
                    <a href="index2.html">Dashboard 2</a>
                  </li>
                  <li>
                    <a href="index3.html">Dashboard 3</a>
                  </li>
                  <li>
                    <a href="index4.html">Dashboard 4</a>
                  </li>
                </ul> */}
              </li>
              <li>
                <a href="chart.html">
                  <i className="fas fa-chart-bar" />
                  Charts
                </a>
              </li>
              <li>
                <a href="#/">
                  <i className="fas fa-calendar-alt" />
                  Calendar
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      {/* Mobile Header End */}
    </>
  );
};

export default MobileNavbar;
