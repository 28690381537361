import React from 'react';

const FullScreenLoader = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <div
        style={{
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          borderTop: '6px solid #fff',
          borderRight: '6px solid #fff',
          borderBottom: '6px solid #fff',
          borderLeft: '6px solid #ccc',
          animation: 'spin 1s linear infinite',
        }}
      />
    </div>
  );
};

export default FullScreenLoader;
